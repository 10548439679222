

/* 채용중인 포지션으로 이동 */
.recommend-job-banner-back{
  display: flex;
  word-break: keep-all;
  background-image: linear-gradient(90deg,#01a9fe -12%,#2f5fef 37%,#046afe 72%,#00d2dd 110%);
  padding: 21px 30px;
  font-size: 17px;
  border-radius: 38.5px;
  line-height: normal;
  color: #fff;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  margin: 60px 0px;
}
.jobsfeed-position-banner-icon{
  font-size: 24px;
  margin-bottom: -3px;
  margin-right: 12px;
}

/* ai 포지션 추천 배너 */

.jobsfeed-ai-position-back{
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #f4f8fb;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
  background-image: url(https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fwanted-dev.static.wanted.co.kr%2Fbanner%2FJobsfeed_ai_banner.png&w=1440&q=75);
}
.jobsfeed-ai-position-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 40px;
}
.jobsfeed-ai-position-img1{
  width: 80px;
  height: 80px;
  margin-right: 8px;
}
.jobsfeed-ai-position-title{
  margin-bottom: 6px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.05;
  letter-spacing: normal;
  color: #111;
}
.wantedai-logo{
  width: 114px;
  vertical-align: -4px;
  margin-right: 3px;
  margin-bottom: 3px;
}
.jobsfeed-ai-position-sub-title{
  margin-bottom: 20px;
  color: #555;
  font-size: 16px;
  line-height: 19.2px;
}
.jobsfeed-ai-position-btn{
  padding: 10px 20px;
  border-radius: 25px;
  background-color: #36f;
  font-size: 15px;
  font-weight: 600;
  color: #fefefe;
  line-height: 22px;
  flex: 0 0 auto;
}

/* 커리어 중간점검 배너 */
.jobsfeed-type-test-banner-back{
  cursor: pointer;
  background: #0d99ff;
  color: #fff;
  border-radius: 10px;
  padding: 32px 40px;
  background-image: url(https://static.wanted.co.kr/career_connect/banner-back-pc-jobsfeed.png);
  background-size: auto 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  margin-top: 60px;
  height: 103px;
  font-size: 22px;
  font-weight: 800;
  line-height: 26px;
  justify-content: space-between;
  position: relative;
}
.jobsfeed-type-test-banner-back >button{
  border-radius: 20px;
  background-color: #fff;
  color: #2b4bc8;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  padding: 11px 20px;
}
.jobsfeed-type-test-banner-back >img{
  width: 124px;
  height: 95px;
  position: absolute;
  right: 250px;
  bottom: -2px;
}


/* 프로필, 매치업 */
.jobsfeed-profile-match-back{
  padding: 60px 0 85px;
}
.jobsfeed-profile-match-box{
  width: 50%;
  height: 80px;
  display: flex;
  justify-content: center;
}
.profile-box{
  background-color: #f2f5ff;
  border-top-left-radius: 38.5px;
  border-bottom-left-radius: 38.5px;
}
.match-box{
  border-top-right-radius: 38.5px;
  border-bottom-right-radius: 38.5px;
  background-color: #f1f1f1;
}

.jobsfeed-profile-match-box>button{
  font-size: 18px;
  font-weight: 500;
}
.jobsfeed-profile-match-box>svg{
  width:28px;
  height: 28px;
  margin-top: 25px;
  margin-right: 10px;
}



/* 첫번째, 두번째, 세번째 소개 배너 */

/* 이벤트 배너 */
.intro-banner-back{
  padding: 60px 0px;
}
.intro-banner-title-box{
  justify-content: space-between;
  margin-bottom: 30px;
}
.intro-banner-title{
  font-size: 22px;
  line-height: 1.33;
  font-weight: 700;
  color: #333;
}
.intro-banner-title>img{
  width: 24px;
  height: 24px;
  margin: 2px 8px 0 4px;
}
.intro-banner-title>span:nth-child(3){
  font-weight: 400;
}
.intro-banner-company-info{
  margin-top: 12px;
  justify-content: space-between;
}
.intro-banner-company-info-left>img{
  margin-right: 12px;
  width: 42px;
  height: 42px;
}
.intro-banner-company-text-box>div:nth-child(1){
  font-size: 18px;
  font-weight: 700;
  color: #333;
  word-break: break-all;
}
.intro-banner-company-text-box>div:nth-child(2){
  font-size: 14px;
  font-weight: 500;
  color: #999;
}

.intro-banner-company-info-follow{
  border: 1px solid #e1e2e3;
  padding: 12px 25.5px 11px;
  line-height: 1;
  height: 40px;
  font-size: 15px;
  font-weight: 700;
  color: #36f;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thema-company-logos >img{
  margin-top: 12px;
}
.thema-company-logos >button{
  font-size: 14px;
  font-weight: 500;
  color: #666;
  margin-left: 4px;
  margin-top: 6px;
}

.intro-banner-sub-title{
  font-size: 15px;
  margin: 5px 0 0;
  font-weight: 500;
  line-height: normal;
  vertical-align: middle;
  color: #767676;
  display: flex;
  justify-content: center;
}
.intro-banner-sub-title>span{
  margin-right: 8px;
}
.intro-banner-btn{
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  align-items: center;
  border: 1px solid #e1e2e3;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
  font-size: 16px;
  color: #767676;
}

.intro-banner-swiper-back{
  width: 100%;
  overflow: hidden;
}
.intro-banner-swiper-contents{
  width: 300vw;
  transition: all 1s;
}
.intro-banner-card{
  width: 530px;
  padding: 8px 8px 8px 0px;
}
.intro-banner-card>img{
  width: 100%;
  height: 273px;
  border-radius: 4px;
  object-fit: cover;
  outline: 1px solid rgba(0,0,0,.05);
}
.intro-banner-card-title{
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #333;

}
.intro-banner-card-date{
  margin-top: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #aaa;
}

/* 디바이더 */
.jobsfeed-divider{
  max-width: 1060px;
  height: 1px;
  background-color: #ececec;
}


/* 요즘뜨는 포지션 */

.jobsfeed-new-position-back{
  padding: 70px 0 50px;
}
.jobsfeed-new-position-title{
  margin-bottom: 30px;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.05;
  color: #111;
}
.jobsfeed-new-position-card{
  width: 25%;
  padding: 10px;
  position: relative;
}
.jobsfeed-new-position-card>img{
  width: 100%;
  height: 187.5px;
  border-radius: 4px;
}

.jobsfeed-new-position-card>div:nth-child(2){
  margin-top: 14px;
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
}
.jobsfeed-new-position-card>div:nth-child(3){
  font-size: 14px;
  line-height: normal;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  color: #333;
  font-weight: 600;
} 
.jobsfeed-new-position-card>div:nth-child(4){
  font-weight: 400;
  color: #999;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
}
.jobsfeed-new-position-card>div:nth-child(5){
  margin-bottom: 14px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.jobsfeed-bookmark-btn{
  position: absolute;
  top: 16px;
  right: 14px;
  color: #fff;
}
.jobsfeed-bookmark-btn>svg{
  width: 22px;
  height: 22px;
}








