

/* .event-page-main-back {
  background-color: #fff;
} */



/* 탑 배너 */

.event-top-banner-back{
  margin-top: 50px;
  width: 100%;
  height: 300px;
  background-image: url("https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fwanted-marketing-image.s3.ap-northeast-2.amazonaws.com%2Fcareer-biz-banner%2F20230203_event_web__2000x300.jpg&w=2000&q=100");
  background-size: cover;
  background-position: 50%;
}


/* 메인 */
.event-page-main-back{
  padding-top: 59px;
}
.event-page-main-title{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 127.3%;
  letter-spacing: -.012em;
  color: #333;
}



.event-page-main-sticky-banner{
  position:sticky;
  top: 45px;
  z-index: 10;
  background-color: #fff;
}


/* 메인 - 캐러샐 */

.event-carousel-back{
  margin: 30px 0px;
  width: 100%;
  justify-content: space-between;
}
.event-carousel-box{
  max-width: calc((100% - 40px) - 8px);
  overflow: hidden;
  position: relative;
}
.event-carousel-contents{
  width: 3000px;
  transition: all 1s;
}
.event-carousel-content-btn{
  padding: 9px 22px;
  font-weight: 600;
  font-size: 14px;
  line-height: 146.7%;
  flex-shrink: 0;
  color: #888;
  border: 1px solid #ececec;
  border-radius: 25px;
  outline: none;
  scroll-snap-align: start;
  height: 40px;
  position: relative;
  margin-right: 8px;
}
.event-click{
  color: #36f;
  border-color: #36f;
}



.event-carousel-move-btn-box{
  position: absolute;
  display: flex;
  width: 80px;
}
.event-carousel-move-btn{  
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
  color: #888;
}
.move-btn-left-box{
  left:0px;
  bottom: 1px;
  justify-content: flex-start;
  background: linear-gradient(90deg,#fff 21.82%,hsla(0,0%,100%,0));
}
.move-btn-right-box{
  right:0px;
  bottom: 1px;
  justify-content: flex-end;
  background: linear-gradient(270deg,#fff 21.82%,hsla(0,0%,100%,0));
}
.event-carousel-open-btn{
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #ececec;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
  color: #939393;
  background-color: #fff;
  outline: none;
}


.event-sticky-divider{
  width: 100%;
  height: 1px;
  background-color: #ececec;
  margin: 25px 0px;
}

/* main - 캐러샐밑에 정렬 */
.event-sticky-filter-alarm{
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.event-sticky-filter-btn{
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 5px;
  border: 1px solid #e1e2e3;
  font-size: 13px;
  font-weight: 500;
  position: relative;
}
.event-sticky-filter-btn-icon{
  margin-right: 7px;
}
.event-sticky-filter-btn-num-box{
  position: absolute;
  top: -7px;
  right: -10px;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0 5px;
  border-radius: 10px;
  background-color: #36f;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
}

.event-sticky-alarm-btn{
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 9999px;
  background-color: #36f;
  margin-left: auto;
  white-space: nowrap;
  color: #fff;
  font-weight: 500;
}



.event-sticky-divider-bottom{
  height: 1px;
  width: 100vw;
  background-color: #ececec;
  /* #36f */
  margin-left: calc(-50vw + 50%);
}


/* main - contents */

.event-page-main-contents-back{
  margin-top: 8px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px 20px;
}
.event-page-main-content-card-back{
  width: 100%;
}
.event-page-main-content-card-back>img{
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.06);
  width: 100%;
  height: 175px;
  object-fit: cover;
}
.event-page-main-content-card-title{
  font-size: 18px;
  line-height: 144.4%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  color: #333;
  margin: 0;
  word-break: keep-all;
  overflow-wrap: anywhere;
}
.event-page-main-content-card-date{
  margin-top: 6px;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: .025em;
  padding: 4px 0;
  color: #939393;
  font-weight: 400;
}

.event-page-main-content-card-tags{
  font-size: 13px;
  line-height: 138.5%;
  letter-spacing: .025em;
  color: #939393;
  font-weight: 600;
}
.event-page-main-content-card-tags>span{
  margin-right: 8px;
}









