
/* top 배너 */
.aiscore-main-top-banner-back{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 81px 0;
  background-color: #fcfdfd;
  margin-top: 50px;
}
.aiscore-main-top-banner-back >div:nth-child(1){
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  color: #111;
  margin-bottom: 10px;
}
.aiscore-main-top-banner-back >div:nth-child(2){
  font-size: 14px;
  text-align: center;
  color: #666;
  font-weight: 400;
  white-space: pre;
  line-height: normal;
}
.aiscore-main-top-banner-btns{
  margin-top: 43px;
}
.aiscore-main-top-banner-btns>button{
  width: 300px;
  height: 180px;
  border: 1px solid #e7e7e8;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.07);
}
.aiscore-main-top-icon{
  width: 30px;
  height: 32px;
  margin-bottom: 25px;
}
.aiscore-main-top-btn-title{
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #333;
  margin-bottom: 7px;
}
.aiscore-main-top-btn-file-info{
  font-size: 13px;
  font-weight: 500;
  color: #999;
}


/* bottom 배너 */

.aiscore-main-bottom-banner-back{
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f4f8fb;
  padding: 46px 0 125px;
  position: relative;
  z-index: -3;
}
.aiscore-main-bottom-banner-color1{
  z-index: -1;
  position: absolute;
  width: 308px;
  height: 308px;
  filter: blur(50px);
  background-color: #e9f4ff;
  top: 0;
  right: 25%;
}
.aiscore-main-bottom-banner-color2{
  z-index: -1;
  position: absolute;
  width: 95px;
  height: 95px;
  filter: blur(58px);
  background-color: rgba(122,168,255,.9);
  bottom: 65px;
  left: 20%;
}
.aiscore-main-bottom-banner-color3{
  z-index: -1;
  position: absolute;
  width: 119px;
  height: 119px;
  filter: blur(50px);
  background-color: #d1ffeb;
  top: 0;
  right: 32%;
}
.aiscore-main-bottom-banner-img-box{
  margin-right: 78px;
  margin-bottom: 18px;
}
.aiscore-main-bottom-banner-img-box>img:nth-child(1){
  width: 135px;
  vertical-align: middle;
}
.aiscore-main-bottom-banner-img-box>img:nth-child(2){
  width: 172px;
  height: 28px;
  margin-top: 65px;
  margin-left: -10px;
  vertical-align: middle;
  margin-bottom: 3px;
}


.aiscore-main-bottom-banner-text{
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #111;
  margin-bottom: 6px;
}

.aiscore-main-bottom-banner-sub-text{
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}







