
.resume-login-back{
  margin-top: 80px;
}

/* top 배너 */
.resume-login-top-banner{
  position: relative;
  width: 100%;
  height: 103px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #2b4bc8;
  cursor: pointer;
  overflow: hidden;
}
.resume-login-top-banner>img:nth-child(1){
  width: 72%;
  z-index: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
}
.resume-login-top-banner>img:nth-child(2){
  width: 333px;
  height: 90px;
  bottom: 0;
  right: 200px;
  z-index: 0;
  position: absolute;
}
.resume-login-top-banner-btn{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px 40px;
  font-size: 20px;
  font-weight: 1000;
  line-height: 39px;
  color: #fff;
  justify-content: space-between;
}
.resume-login-top-banner-btn>div:nth-child(2){
  z-index: 1;
  border-radius: 20px;
  background-color: #fff;
  color: #2b4bc8;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  padding: 11px 20px;
}



/* 컨텐츠 */
.resume-login-contents-title{
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 5px;
  padding: 15px 0;
}
.resume-login-contents-title>div:nth-child(1){
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  color: #333;
  word-wrap: break-word;
}
.resume-login-contents-title>div:nth-child(2){
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  color: #36f!important;
}

.resume-login-contents-cards-back{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.resume-login-contents-cards-back>button{
  height: 190px;
  /* width: calc(25% - 20px); */
  position: relative;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  cursor: pointer;
}

.resume-login-write-box>div{
  background-color: #36f;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.resume-login-write-box>p{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  margin: 20px 0 0;
}

.resume-login-upload-box>div{
  background-color: #e1e2e3;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.resume-login-upload-box>p{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  margin: 20px 0 0;
}



.resume-login-resume-box{
  justify-content: space-between;
  padding-top: 15px;
  position: relative;
}
.resume-login-resume-box-text>div:nth-child(1){
  height: 24px;
  width: 100px;
  background-color: #f3f9fe;
  color: #36f;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.2px;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;
  padding-left: 18px;
}
.resume-login-resume-box-text>div:nth-child(2){
  font-size: 17px;
  font-weight: 600;
  line-height: 1.33;
  max-height: 46px;
  max-width: 100%;
  letter-spacing: normal;
  text-align: left;
  color: #333;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  border: none;
  padding-right: 18px;
  padding-left: 18px;
}
.resume-login-resume-box-text>div:nth-child(3){
  color: #999;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-left: 18px;
}

.resume-login-resume-box-bottom{
  height: 41px;
  width: 100%;
  padding: 0 12px 0 0;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  position: absolute;
  bottom: 0;
}

.resume-login-resume-box-bottom>div:nth-child(1){
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #333;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 18px;
}
.resume-login-resume-box-bottom>div:nth-child(2){
  font-weight: 600;
}
.resume-login-resume-box-bottom>div:nth-child(3){
  margin-left: auto;
  margin-right: 18px;
}





