

.community-main-back{
  margin-top: 50px;
  background-color: #f8f8f8;
}
.community-main-contents-box{
  padding-top: 60px;
}
.community-main-contents-hidden-box{
  width: 100%;
  height: 50px;
  background-color: #f8f8f8;
  position: fixed;
  top: 50px;
  z-index: 10;
}


/* left 박스 */
.community-main-left-box-back{
  margin-right: 20px;
  width: 258px;
}
.community-main-left-box-contents-box{
  position: fixed;
  top: 100px;
}
.community-main-left-box-my-community{
  width: 258px;
  height: 123px;
  padding: 23px 24px;
  background-color: #fff;
  border: 1px solid #e1e2e3;
  border-radius: 5px;
  margin-bottom: 14px;
}
.community-main-left-box-my-community>div:nth-child(1){
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.community-main-left-box-my-info>img{
  width: 38px;
  height: 38px;
  border-radius: 19px;
}
.community-main-left-box-my-info>div{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 10px;
  color: #666;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  line-height: 38px;
  color: #000;
}
.community-main-left-box-banner{
  width: 258px;
  height: 88px;
  padding: 0 18px;
  background: no-repeat right 5px top 10px/77px url(https://static.wanted.co.kr/images/community/identify_people.png),linear-gradient(270deg,#01a9fe .24%,#2f5fef);
  border-radius: 6px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 700;
  color: #f0f0f0;
}

.community-main-left-box-update-back{
  width: 258px;
  padding: 23px;
  background: #fff;
  border: 1px solid #e1e2e3;
  border-radius: 5px;
}
.community-main-left-box-update-back>div:nth-child(1){
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  margin-bottom: 8px;
}
.community-main-left-box-update-back>div:nth-child(2){
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666;
  margin-bottom: 24px;
}
.profile-info{
  margin-bottom: 20px;
}
.profile-info>div{
  font-size: 13px;
  font-weight: 500;
}
.community-main-left-box-profile-btn{
  color: #666;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  border: 1px solid #e1e2e3;
  height: 40px;
  width: 100%;
  padding: 0 27px;
  border-radius: 25px;
}



/* right box */
.community-main-right-box-back{
  width: 790px;
}



/* main - 오른쪽 상단 캐러샐 */
.community-carousel-back{
  width: 784px;
  border: 1px solid #e1e2e3;
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 19px 30px;
  position: fixed;
  top: 100px;
  justify-content: space-between;
  background-color: #fff;
  z-index: 10;
}
.community-carousel-box{
  max-width: calc((100% - 40px) - 8px);
  overflow: hidden;
  position: relative;
}
.community-carousel-contents{
  width: 3000px;
}
.community-carousel-content-btn{
  padding: 9px 22px;
  font-weight: 600;
  font-size: 14px;
  line-height: 146.7%;
  flex-shrink: 0;
  color: #888;
  border: 1px solid #ececec;
  border-radius: 25px;
  outline: none;
  scroll-snap-align: start;
  height: 40px;
  position: relative;
  margin-right: 8px;
}

.community-carousel-move-btn-box{
  position: absolute;
  display: flex;
  width: 80px;
}
.community-carousel-move-btn{  
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
  color: #888;
}
.move-btn-left-box{
  left:0px;
  bottom: 1px;
  justify-content: flex-start;
  background: linear-gradient(90deg,#fff 21.82%,hsla(0,0%,100%,0));
}
.move-btn-right-box{
  right:0px;
  bottom: 1px;
  justify-content: flex-end;
  background: linear-gradient(270deg,#fff 21.82%,hsla(0,0%,100%,0));
}
.community-carousel-open-btn{
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #ececec;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
  color: #939393;
  background-color: #fff;
  outline: none;
}


/* main-right-이번주많이 본글, 버튼 */

.community-main-right-box-contents-back{
  margin-top: 70px;
  background-color: #fff;
}
.community-right-box-this-week{
  padding: 30px;
  border: 1px solid #e1e2e3;
  border-top: 0;
}
.community-right-box-this-week-title-back{
  justify-content: space-between;
  margin-bottom: 20px;
}
.community-right-box-this-week-title-back>div:nth-child(1){
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}
.community-right-box-nav-num>span{
  font-size: 12px;
  margin-right: 4px;
  color: #888;
}
.community-right-box-nav-btn{
  width: 24px;
  height: 24px;
  color: #333;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  border: 1px solid #e1e2e3;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 5px;
}
.community-right-box-this-week-card{
  margin-right: 15px;
  background-color: #eff1fb;
  display: block;
  flex: 0 0 236px;
  padding: 15px 20px 19px;
  border-radius: 10px;
}
.community-right-box-this-week-card>div:nth-child(1){
  font-size: 14px;
  font-weight: 700;
  color: #939393;
  margin-bottom: 8px;
  display: -webkit-box;
  text-overflow: ellipsis;
}
.community-right-box-this-week-card>div:nth-child(2){
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.38;
  min-height: 45px;
  margin-bottom: 20px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.community-right-box-this-week-card-profile{
  align-items: center;
}
.community-right-box-this-week-card-profile>img{
  width: 28px;
  height: 28px;
  object-fit: cover;
  clip-path: circle(50% at 50% 50%);
  margin-right: 7px;
}
.community-right-box-this-week-card-profile>div{
  font-size: 13px;
  font-weight: 600;
}


.community-right-box-write-btn-back{
  padding: 29px 29px 31px;
  border: 1px solid #e1e2e3;
  border-top: 0;
  background-color: #fff;
  align-items: center;
}
.community-right-box-write-btn{
  width: 100%;
  height: 56px;
  padding: 0 19px;
  border-radius: 4px;
  border: 1px solid #e1e2e3;
  background-color: #fff;
  cursor: pointer;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  align-items: center;
  justify-content: space-between;
}


/* main- right 메인 컨텐츠 카드들 */

.community-main-contents-cards-back{
  border: 1px solid #ececec;
  border-top: 0;
  width: 100%;
  background-color: #fff;
}

.community-main-contents-header{
  height: 107px;
  padding: 30px 29px 0;
  margin-top: 0;
  position: relative;
}
.community-main-contents-header>div:nth-child(1){
  margin-bottom: 11px;
  font-size: 20px;
  font-weight: 700;
  color: #333;
}
.community-main-contents-header>div:nth-child(2){
  color: #767676;
  font-size: 14px;
  font-weight: 500;
}
.community-main-contents-header::after{
  width: 730px;
  content: "";
  height: 1px;
  background-color: #ececec;
  position: absolute;
  bottom: 0;
}

.community-main-contents-card-back{
  padding: 29px 29px 30px;
  margin-bottom: 2px;
  position: relative;
}
.community-main-contents-card-back::after{
  width: 730px;
  content: "";
  height: 1px;
  background-color: #ececec;
  position: absolute;
  bottom: 0;
}


.community-main-contents-card-profile{
  margin-bottom: 9px;
}

.community-main-contents-card-profile>img{
  width: 28px;
  height: 28px;
  clip-path: circle(50% at 50% 50%);
  object-fit: cover;
  margin-right: 7px;
}

.community-main-contents-card-profile-text>span:nth-child(1){
  margin-right: 4px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
}
.community-main-contents-card-profile-text>span:nth-child(2){
  margin-right: 4px;
  color: #5866dc;
  align-items: center;
  padding: 0 4px;
  height: 19px;
  background: #f6f6f6;
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 19px;
}
.community-main-contents-card-profile-text>span:nth-child(3){
  padding: 0 4px;
  height: 19px;
  background: #f6f6f6;
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 19px;
  color: #888;
}
.community-main-contents-card-title{
  font-size: 19px;
  font-weight: 700;
  color: #333;
  margin-bottom: 5px;
  line-height: 23px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.community-main-contents-card-text{
  margin-bottom: 10px;
  min-height: 40px;
  font-size: 14px;
  color: #666;
  line-height: 1.43;
  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.community-main-contents-card-tags{
  margin-bottom: 21px;
}
.community-main-contents-card-tags>button{
  padding: 0 8px;
  align-items: center;
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 400;
  color: #888;
  height: 26px;
  margin-right: 10px;
}

.community-main-contents-card-btn{
  width: 18px;
  height: 18px;
  margin: 0 8px;
}




