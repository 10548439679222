
/* header */
.mainheader-back{
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 10%);
  z-index: 800;
  background-color: #fff;
}
.mainheader-content{
  display: flex;
  justify-content: space-between;
}

.mainheader-left{
  align-items: center;
}

.mainheader-btn{
  margin-top: -2px;
  margin-right: 15px;
}

.header_logo{
  width: 80px;
  height: 20px;
}

.mainheader-center a{
  padding: 15px 15px 15px 15px;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  position: relative;
  height: 50px;
}
.mainheader-center a:hover{
  border-bottom: 2px solid #cbcaca;
}
.mainheader-label{
  position: absolute;
  top: 10px;
  right: -5px;
  pointer-events: none;
  font-style: normal;
}

.mainheader-right{
  height: 100%;
}
.mainheader-user-alarm-bell{
  width: 22px;
  height: 22px;
  margin: 0px 10px 0px 4px;
}
.mainheader-user-profile-img{
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-top:-3px;
}
.login-click-triangle{
  z-index: 850;
  position: absolute;
  top: 15px;
  right: -1px;
  width: 0;
  height: 0;
  border-bottom: 15px solid #fff;
  /* box-shadow: 0px 1px 0px 0px #cdcdcd; */
  border-top: 15px solid transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.login-click-box{
  z-index: 849;
  position: absolute;
  top: 43px;
  right: -130px;
  min-width: 194px;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 0 rgb(0 0 0 / 30%);
  border: 1px solid #cdcdcd;
  background-color: #fff;
  padding-top: 14px;
}
.login-click-box>div{
  height: 34px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  color: #333;
}
.login-click-divider{
  height: 1px !important;
  width: 100%;
  background-color: #ececec;
  margin-top: 0px;
  margin-bottom: 10px;
}



.search-li{
  margin-top: 3px;
  padding: 0 10px;
}
.serach-icon{
  font-size: 18px;
}
.login-btn{
  margin-top: 3px;
  margin-right: 6px;
  line-height: 1.4;
  padding: 0 10px;
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.header-divider{
  width: 1px;
  height: 10px;
  background-color: #bedbf9;
  margin: auto 10px;
}
.service-btn{
  font-size: 13px;
  color: #666;
  line-height: 30px;
  height: 30px;
  border: 1px solid #e1e2e3;
  border-radius: 15px;
  padding: 0 10px;
  margin-left: 15px;
  margin-top: -3px;
  font-weight: 400;
}


/* footer */
.main-footer-back{
  width: 100%;
  border-top:1px solid #ececec;
  padding: 18px 0px 65px;
  background-color: #fff;
}

.main-footer-top-contents{
  width: 100%;
  margin: 0 auto 18px;
  border-bottom: 1px solid #ececec;
  padding-bottom: 25px;
  justify-content: space-between;
}
.main-footer-wanted-logo{
  width: 113px;
  margin-right: 43px;
}

.main-footer-navbar-content{
  font-size: 15px;
  color: #3a3a3a;
  font-weight: 500;
  line-height: 2.6;
  margin-right: 45px;
  white-space: nowrap;
}
.personal-info{
  font-weight: 600;
}

.main-footer-share-icon>img{
  height: 20px;
  margin-left: 14px;
}


.main-footer-bottom-contents{
  justify-content: space-between;
}



.main-footer-bottom-text{
  float: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6666666667em;
  color: #767676;
  width: calc(100% - 290px);
  margin-right: 40px;
}


.main-footer-languege-select-box{
  display: flex;
  justify-content: center;
  position: relative;
}
.main-footer-languege-select-img{
  position: absolute;
  left: 15px;
  top: 13px;
  width: 24px;
  height: 17px;
  border-radius: 2px;
  z-index: 1;
  border: 1px solid #e1e2e3;
}

.main-footer-languege-select{
  width: 250px;
  height: 40px;
  background: #f2f4f7;
  border-radius: 5px;
  padding-left: 45px;
  font-size: 14px;
  font-weight: 500;
  min-height: 34px;
  border-color: #e1e2e3;
}


/* search pop up */

.search-modal-back{
  width: 100vw;
  height: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
  z-index: 5;
}

.search-modal-contents-box{
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 50px;
}

.search-modal-form{
  position: relative;
}
.search-modal-form>input{
  margin-bottom: 50px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  padding: 0 36px 0 60px;
  border: 1px solid #f2f4f7;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  color: #767676;
  background-color: #f2f4f7;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-modal-form>button{
  position: absolute;
  top: 26px;
  left: 20px;
  transform: translateY(-50%);
  color: #939393;
  width: 18px;
  height: 18px;
  margin: 0 16px 0 6px;
}
.search-modal-text-title{
  justify-content: space-between;
  margin-bottom: 20px;
}
.search-modal-text-title>div:nth-child(1){
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #333;
}
.search-modal-text-title>div:nth-child(2){
  float: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #767676!important;
}


.search-modal-btns-box>button{
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding: 0 26px;
  border-radius: 25px;
  margin-right: 10px;
}
.search-modal-btns-box>button:nth-child(1){
  background: #f0f8f8;
}
.search-modal-btns-box>button:nth-child(2){
  background: #eeedf4;
}
.search-modal-btns-box>button:nth-child(3){
  background: #e8edf3;
}
.search-modal-btns-box>button:nth-child(4){
  background: #e9f4fb;
}
.search-modal-btns-box>button:nth-child(5){
  background: #effbf3;
}

