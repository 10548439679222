.login-back{
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  width: 100%;
  height: 100vh;
}

.login-content{
  max-width: 400px;
  width: 100%;
  margin: auto;
  border-radius: 5px;
  width: 100%;
  max-height: calc(100vh - 100px);
  background-color: #fff;
  border: 1px solid #e1e2e3;
  padding: 20px;
  overflow: auto;
}
.login-content::-webkit-scrollbar {
  display: none;
}

.login-logo{
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
  padding-top: 56px;
  display: flex;
  justify-content:center;
}
.login-logo-svg{
  width: 93px;
  height: 28px;
}
.login-title{
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.login-sub-title{
  color: #888;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
  margin-bottom: 45px;
}
.email-text{
  margin: 17px 0px 7px;
  font-size: 14px;
  color: #888888;
}
.login-form{
  width: 100%;
}
.email-input{
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0px 12px;
  outline: none;
  background-color: transparent;
  border: 1px solid #e1e2e3;
  color: #333;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
}
.email-input::placeholder{
  color: #ccc;
}
.email-input:focus{
  border-color: #36f;
}
.email-alert-p{
  margin-bottom: 8px;
  margin-top: 0px;
  text-align: left;
  color: #fe415c;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}


.email-form-btn{
  display: block;
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-radius: 25px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 30px;
  color: #ccc;
  background-color: #f2f4f7;
  border: none;
  cursor: default;
}
.or{
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: center;
  color: #939393;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.social-btn-box{
  justify-content: center;
}

.social-btn{
  flex: 1;
  margin: 0;
  max-width: 25%;
  position: relative;
}

.social-logo{
  width: 56px;
  height: 56px;
}
.social-btn >p{
  margin-top: 6px;
  margin-bottom: 0;
  color: #888;
  font-weight: 500;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  text-transform: capitalize;
}

.google-logo-border{
  position: absolute;
  left: 16px;
  top: 0px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #e1e2e3;
  box-sizing: border-box;
}


.forget-email{
  /* color: #767676; */
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 14px;
}

.login-page-divider{
  border: none;
  width: 100%;
  border-bottom: 1px solid #e1e2e3;
  margin-top: 30px;
  margin-bottom: 30px;
}
.policy{
  margin: 30px auto 15px auto;
  text-align: center;
}
.en-logo-text{
  text-align: center;
  margin-bottom: 30px;
  color: #888;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.languege-select-box{
  display: flex;
  justify-content: center;
  position: relative;
}
.languege-select-img{
  position: absolute;
  left: 125px;
  top: 9px;
  width: 23px;
  height: 16px;
  border-radius: 2px;
  z-index: 1;
  border: 1px solid #e1e2e3;
}

.languege-select{
  width: 140px;
  padding-left: 45px;
  font-size: 12px;
  font-weight: 600;
  height: 34px;
  min-height: 34px;
  border-color: #e1e2e3;
}



/* signup */
.signup-content{
  padding-top: 0px;
  position: relative;
}

.signup-header{
  padding: 0px;
  height: 64px;
  flex: 0 0 auto;
  width: 100%;
  justify-content: space-around;
}
.signup-cancel-btn{
  min-width: 64px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
}
.signup-title{
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.signup-form-box{
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 164px);
}
.signup-form-box::-webkit-scrollbar {
  display: none;
}


.signup-form{
  margin-top: 37px;
}


.signup-email-title{
  margin: 17px 0px 7px;
  text-align: left;
  color: #888;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.signup-email-input{
  background-color: #f2f4f7;
  color:#ccc;
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0px 12px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  border: 1px solid #e1e2e3;
  border-radius: 5px;
}
.signup-email-input::placeholder{
  color:#ccc;
}

.signup-name-input{
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0px 12px;
  outline: none;
  background-color: transparent;
  border: 1px solid #e1e2e3;
  color: #333;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
.signup-name-input::placeholder{
  color: #ccc;
}

.signup-num-input{
  width: 60%;
}

.signup-num-btn{
  color: #ccc;
  border: 1px solid #f2f4f7;
  background-color: #f2f4f7;
  cursor: default;
  height: 50px;
  min-height: 50px;
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0px 15px;
  width: auto;
  flex: 0 0 auto;
  border-radius: 5px;
}



.country-select{
  height: 50px;
  min-height: 50px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e1e2e3;
  margin-bottom: 10px;
  position: relative;
  padding-left: 12px;
  font-size: 16px;
  font-weight: 400;
}

.signup-password-exactly-test{
  color: #fe415c;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 8px;
  margin-top: 0px;
}



.signup-pw-info-text{
  color: #888;
  font-weight: 400;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.total-agree-box{
  min-height: 25px;
  width: 100%;
  margin: 28px 0px 10px;
  position: relative;
}
.signup-checkbox-input{
  /* opacity: 0; */
  width: 18px;
  height: 18px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #ececec;
}
.agree-title-text{
  color: #333;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-left: 7px;
}
.signup-divider{
  margin: 8px 0px;
}

.sub-agree-box{
  margin: 10px 0px;
}
.agree-sub-title-text{
  color: #888;
  font-weight: 500;
}


.agree-label{
  height: 22px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-right: 18px;
  cursor: pointer;
  color: #888;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.agree-check-svg{
  width: 16px;
  height: 16px;
  margin-right: 7px;
}


.signup-submit-btn-box{
  z-index: 10;
  position: sticky;
  bottom: 0px;
}
.signup-submit-btn-upper{
  position: absolute;
  left: 0px;
  right: 0px;
  height: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #fff 100%);
  z-index: -1;
  pointer-events: none;
}
.signup-submit-btn-lower{
  position: absolute;
  inset: 30px 0px 0px;
  background: #fff;
  z-index: -1;
}
.signup-submit-btn{
  color: #ccc;
  background-color: #f2f4f7;
  border: none;
  cursor: default;
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-radius: 25px;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
}



/* password */

.password-content{
  padding: 0px;
}

.password-header{
  padding: 0px 20px;
  height: 64px;
  flex: 0 0 auto;
  width: 100%;
  justify-content: space-between;
}
.password-header-btn{
  width: 64px;
  height: 64px;
  padding: 8px;
  /* margin: -8px; */
}
.password-header-title{
  color: #000;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.password-header-right{
  width: 64px;
  height: 64px;
}

.password-form-box{
  overflow: auto;
  position: relative;
  padding: 20px;
}
.password-form-text{
  margin: 17px 0px 7px;
  color: #888;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
}
.password-form-input{
  width: 100%;
  height: 50px;
  min-height: 50px;
  padding: 0px 12px;
  outline: none;
  background-color: transparent;
  border: 1px solid #e1e2e3;
  color: #333;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}
.password-form-input::placeholder{
  color: #e1e2e3;
}
.password-form-btn{
  color: #ccc;
  background-color: #f2f4f7;
  border: none;
  cursor: default;
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-radius: 25px;
  font-size: 16px;
  margin-bottom: 10px;
  /* cursor: pointer;
  background-color: var(--theme-palette-colors-blue-400);
  border: none; */
  margin-top: 30px;
}


.password-from-change-btn{
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-radius: 25px;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff;
  border: none;
  margin-top: 10px;
  color: #36f;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}





