


/* 탑 캐러샐 */
.swiper-back{
  width: 100%;
  height: 300px;
  margin-top: 75px;
  overflow: hidden;
  position: relative;
}
.swiper-contents{
  height: 100%;
  transition: all 1s;
}

.swiper-card{
  width: 100%;
  max-width: 1060px;
  margin: 0px 12px;
  height: 100%;
  position: relative;
}
.swiper-card img{
  width: 100%;
  height: 100%;
}

.top-swiper-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 120px;
  width: 30px;
  height: 60px;
  opacity: .5;
  border-radius: 15px;
  background-color: #fff;
  font-size: 16px;
  z-index: 10;
}
.top-swiper-before-btn{
  left: calc((100% - 1210px)/2);
}
.top-swiper-next-btn{
  right: calc((100% - 1200px)/2);
}
.top-swiper-btn-svg{
  width: 16px;
  height: 16px;
}

.swiper-text-box{
  position: absolute;
  bottom: 28px;
  width: 330px;
  height: 146px;
  border-radius: 4px;
  background-color: #fff;
  opacity: 1;
  text-align: left;
  left: 34px;
}
.swiper-text-box-title{
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  line-height: 1.5;
  margin-top: 20px;
  font-weight: 700;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.swiper-text-box-sub-title{
  margin: 0 20px;
  height: 44px;
  font-size: 14px;
  line-height: 1.64;
  color: #333;
  font-weight: 500;
}
.swiper-text-box-hr{
  height: 1px;
  margin: 0;
  border: none;
  background-color: #ececec;
}
.swiper-text-box-link>div{
  color: #36f;
  height: 40px;
  font-size: 15px;
  padding: 6px 8px;
  margin: 6px 0 0 13px;
  min-width: 64px;
  justify-content: center;
  vertical-align: middle;
  font-weight: 700;
}

/* .carousel-card{
  height: 300px;
} */










/* 인사이트 */
.insight-back{
  padding: 50px 0px;
}
.insight-title-box{
  text-align: center;
  line-height: 1.33;
  font-weight: 700;
  color: #333;
  font-size: 22px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.insight-title-btn{
  margin-left: 4px;
  margin-top: 3px;
}
.insight-carousel-back{
  margin: 30px 0px;
  width: 100%;
  justify-content: space-between;
}
.insight-carousel-box{
  max-width: calc((100% - 40px) - 8px);
  overflow: hidden;
  position: relative;
}
.insight-carousel-contents{
  width: 2200px;
  transition: all 1s;
}

.insight-carousel-move-btn-box{
  position: absolute;
  display: flex;
  width: 80px;
}
.insight-carousel-move-btn{  
  width: 40px;
  height: 40px;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
  color: #888;
}
.move-btn-left-box{
  left:0px;
  bottom: 1px;
  justify-content: flex-start;
  background: linear-gradient(90deg,#fff 21.82%,hsla(0,0%,100%,0));
}
.move-btn-right-box{
  right:0px;
  bottom: 1px;
  justify-content: flex-end;
  background: linear-gradient(270deg,#fff 21.82%,hsla(0,0%,100%,0));
}
.insight-cards-back{
  margin-bottom: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 32px 20px;
}
.insight-card-img-box{
  width: 100%;
  height: 175px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.06);
  overflow: hidden;
}
.insight-card-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.insight-card-title{
  margin-top: 12px;
  padding-right: 4px;
  font-size: 18px;
  line-height: 144.4%;
  letter-spacing: -.0002em;
  font-weight: 600;
  color: #333;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all; 
}
.insight-card-subtitle{
  margin-top: 4px;
  padding-right: 4px;
  font-size: 13px;
  line-height: 138.5%;
  color: #939393;
  font-weight: 400;
  letter-spacing: .025em;

  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all; 
}

.insight-card-brand{
  margin-top: 12px;
  padding: 1px 0px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 133.3%;
  font-weight: 400;
  letter-spacing: .031em;
  color: #888;
}


.insight-card-brand-img{
  border-radius: 50%;
  border: 1px solid #ececec;
  width: 24px;
  height: 24px;
  margin-right: 6px;
}


.insight-carousel-content-btn{
  padding: 9px 22px;
  font-weight: 600;
  font-size: 14px;
  line-height: 146.7%;
  flex-shrink: 0;
  color: #888;
  border: 1px solid #ececec;
  border-radius: 25px;
  outline: none;
  scroll-snap-align: start;
  height: 40px;
  position: relative;
  margin-right: 8px;
}
.insight-click{
  color: #36f;
  border-color: #36f;
}




.insight-carousel-open-btn{
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #ececec;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
  color: #939393;
  background-color: #fff;
  outline: none;
}



.insight-plus-btn-box{
  display: flex;
  justify-content: center;
}
.insight-plus-btn{
  border: 1px solid #ececec;
  height: 50px;
  justify-content: center;
  vertical-align: middle;
  width: 345px;
  min-width: 64px;
  padding: 0 27px;
  border-radius: 25px;
  color: #333;
  font-size: 15px;
  font-weight: 700;
}




/* 추천 배너 */

.type-test-banner-back{
  cursor: pointer;
  background: #0d99ff;
  color: #fff;
  border-radius: 10px;
  padding: 28px 0 22px 120px;
  background-image: url(https://static.wanted.co.kr/images/type-test/typetest-banner-image.png);
  background-size: auto 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  margin-bottom: 48px;
}
.type-test-banner-back>div:nth-child(1){
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
}
.type-test-banner-back>div:nth-child(2){
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: .0056em;
  height: 32px;
}

.recommend-creator-banner-back{
  background: #fff;
  border: 1px solid #ececec;
  box-shadow: 4px 4px 15px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  grid-gap: 27px;
  gap: 27px;
  position: relative;
  margin-bottom: 60px;
}

.recommend-creator-banner-title{
  word-break: keep-all;
  font-weight: 600;
  font-size: 20px;
  line-height: 141.2%;
  color: #333;
  margin-bottom: 6px;
}
.recommend-creator-banner-link{
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: .0056em;
  padding: 4px 0;
  width: fit-content;
  justify-content: flex-start;
  color: #36f;
}
.recommend-creator-banner-cancel{
  position: absolute;
  right: 13px;
  top: 13px;
  font-size: 20.5px;
  color: #ccc;
  cursor: pointer;
}

.recommend-job-banner-back{
  display: flex;
  word-break: keep-all;
  background-image: linear-gradient(90deg,#01a9fe -12%,#2f5fef 37%,#046afe 72%,#00d2dd 110%);
  padding: 21px 30px;
  font-size: 17px;
  border-radius: 38.5px;
  line-height: normal;
  color: #fff;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}


/* 이벤트 배너 */

.event-banner-back{
  padding: 60px 0px;
}
.event-banner-title-box{
  justify-content: space-between;
  margin-bottom: 30px;
}
.event-banner-title{
  font-size: 22px;
  line-height: 1.33;
  font-weight: 700;
  color: #333;
}
.event-banner-sub-title{
  font-size: 15px;
  margin: 5px 0 0;
  font-weight: 500;
  line-height: normal;
  vertical-align: middle;
  color: #767676;
  display: flex;
  justify-content: center;
}
.event-banner-sub-title>span{
  margin-right: 8px;
}
.event-banner-btn{
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  align-items: center;
  border: 1px solid #e1e2e3;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
  font-size: 16px;
  color: #767676;
}
.disabled-btn{
  cursor: not-allowed;
  opacity: .7;
  color: #ddd;
  border: 1px solid #eee;
}



.event-banner-swiper-back{
  width: 100%;
  overflow: hidden;
}
.event-banner-swiper-contents{
  width: 300vw;
  transition: all 1s;
}
.event-banner-card{
  width: 530px;
  padding: 8px 8px 8px 0px;
}
.event-banner-card>img{
  width: 100%;
  height: 273px;
  border-radius: 4px;
  object-fit: cover;
  outline: 1px solid rgba(0,0,0,.05);
}
.event-banner-card-title{
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #333;

}
.event-banner-card-date{
  margin-top: 3px;
  font-size: 13px;
  font-weight: 500;
  color: #aaa;
}



/* 채용 정보 */
.apply-info-divider{
  width: 100%;
  height: 1px;
  background-color: #ececec;
}
.apply-info-title{
  margin-top: 60px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 22px;
  line-height: 1.33;
  font-weight: 700;
  color: #333;
}
.apply-info-contents-box{
 margin-bottom: 80px;
 width: 100%;
}
.apply-info-content{
  width: 25%;
  padding: 18px 0 0;
  border: 1px solid #ececec;
  align-items: center;
}
.apply-info-link{
  width: 32px;
  height: 32px;
}
.apply-info-icon{
  width: 32px;
  height: 32px;
}
.apply-info-btn{
  height: 50px;
  font-size: 16px;
  padding: 6px 8px;
  justify-content: center;
  vertical-align: middle;
  min-width: 64px;
}





