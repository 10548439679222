@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body{
  font-size: 14px;
  font-family: 'Pretendard-Regular';
  line-height: 1.42857143;
  color: #333333;
  /* background-color: #fff; */
  background-color: #fff;
}
button{
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}
a{
 text-decoration: none; 
 color:#333333;
}
ol, ul {
  list-style: none;
  padding: 0px;
}
* {
  box-sizing: border-box;
}


.main-container{
  max-width: 1060px;
  margin: 0 auto;
}
.flex-row{
  display: flex;
}
.flex-column{
  display: flex;
  flex-direction: column;
}

.icon-style{
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 2px;
}




