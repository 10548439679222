
.salary-chart-back{
  margin-top: 50px;
  position: relative;
  min-height: 350px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #22bd79;
}

.salary-chart-contents{
  width: 90%;
  max-width: 1060px;
  margin: 0 auto;
}

.salary-chart-box{
  width: 74%;
  margin-right: 20px;
}
.salary-chart-text-tags{
  display: block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 10px;
  color: #22bd79;
  background-color: #fff;
  border-radius: 3px;
  white-space: pre;
  font-size: 16px;
  font-weight: 600;
}
.salary-chart-text-expext-salary{
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 1.8;
  white-space: pre-wrap;
}

.salary-chart-text-price>span:nth-child(1){
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  line-height: 1.4;
  white-space: pre-wrap;
  margin-right: 16px;
}
.salary-chart-text-price>span:nth-child(2){
  font-size: 14px;
  font-weight: 400;
  font-weight: 700;
  color: #fff;
}

.salary-chart-select-contents-back{
  position: absolute;
  left: 0;
  right: 0;
  bottom: -24px;
}
.salary-chart-select-contents-box{
  width: 90%;
  max-width: 1060px;
  margin: 0 auto;
}
.salary-chart-select{
  width: 25%;
  border-right: 1px solid #eee;
  height: 43px;
  border: none;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid #e1e2e3;
  background-color: #fff;
  font-size: 15px;
}



/* main */

.salary-description{
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: #b5b5b5;
}

.salary-middle-banner{
  margin: 42px 0 40px;
  padding: 55px;
  background: #fff;
}

.salary-middle-banner>div{
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  word-break: keep-all;
}

.salary-middle-banner>div:nth-child(1){
  font-size: 40px;
  font-weight: 600;
  color: #258bf7;
  margin: 0 0 20px;
}
.salary-middle-banner>div:nth-child(2){
  font-size: 20px;
  font-weight: 400;
  color: #000;
  margin: 0 0 40px;
}

.salary-middle-banner>img{
  width: 100%;
  height: 55px;
  margin: 0px -6px 30px;
}
.salary-middle-banner>button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  border: 0;
  border-radius: 27px;
  background-color: #36f;
  font-size: 16px;
  font-weight: 600;
  color: #fff!important;
  cursor: pointer;
}


.salary-position-cards-title{
  margin-bottom: 20px;
  margin-left: 7px;
  display: inline-block;
  padding: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}


.salary-position-back{
  margin-bottom: 60px;
}

.salary-position-contents{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
}
.salary-position-card{
  width: 100%;
  padding: 10px;
  position: relative;
}
.salary-position-card>img{
  width: 100%;
  height: 187.5px;
  border-radius: 4px;
}

.salary-position-card>div:nth-child(2){
  margin-top: 14px;
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
}
.salary-position-card>div:nth-child(3){
  font-size: 14px;
  line-height: normal;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  color: #333;
  font-weight: 600;
} 
.salary-position-card>div:nth-child(4){
  font-weight: 400;
  color: #999;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
}
.salary-position-card>div:nth-child(5){
  margin-bottom: 14px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.salary-bookmark-btn{
  position: absolute;
  top: 16px;
  right: 14px;
  color: #fff;
}
.salary-bookmark-btn>svg{
  width: 22px;
  height: 22px;
}








