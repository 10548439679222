

/* 헤더 */
.jobslist-header-back{
  padding-top: 90px;
}
.jobslist-header-title{
  padding-top: 3px;
  margin-right: 15.5px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #333;
}
.jobslist-header-btn{
  border: 1px solid #e1e2e3;
  border-radius: 9999px;
  background-color: #fff;
  width: 26px;
  height: 26px;
  margin-top: 5px;
}
.jobslist-header-divider{
  width: 2px;
  line-height: 29px;
  background-color: #ececec;
  margin: 3px 28px 0px;
}
.jobslist-header-text{
  padding-top: 3px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
  color: #aaa;
}


/* main */

/* main 상단패널 */
.jobslist-main-back{
  padding: 20px 0 80px;
}
.jobslist-main-sticky-banner{
  position: sticky;
  top: 50px;
  background-color: #fff;
  z-index: 10;
}
.jobslist-main-select-banner{
  padding-top: 10px;
  justify-content: space-between;
}
.jobslist-main-area-btn{
  height: 40px;
  padding: 0 15px;
  margin-bottom: 0;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ececec;
  position: relative;
  align-items: center;
}
.jobslist-main-area-btn>span:nth-child(1){
  margin-right: 15px;
}

.jobslist-main-area-btn>span:nth-child(2){
  color: #36f;
  font-weight: 600;
}
.jobslist-main-area-btn-num{
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #36f;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 7px;
}


.jobslist-main-divider{
  height: 1px;
  margin: 25px auto;
  background-color: rgba(236,236,236,.7);
}

/* main 상단페널 캐러샐 */

.jobslist-carousel-back{
  margin: 30px 0px;
  width: 100%;
  justify-content: space-between;
}
.jobslist-carousel-box{
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.jobslist-carousel-contents{
  width: 2200px;
  transition: all 1s;
}

.jobslist-carousel-content-btn{
  padding: 8px 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  flex-shrink: 0;
  color: #333;
  border: 1px solid #ececec;
  border-radius: 20px;
  outline: none;
  scroll-snap-align: start;
  height: 32px;
  position: relative;
  margin-right: 8px;
}
.jobslist-click{
  border: 1px solid #36f;
  color: #36f;
  font-weight: 600;
}
.jobslist-carousel-move-btn-box{
  position: absolute;
  display: flex;
  width: 80px;
}
.jobslist-carousel-move-btn{  
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ececec;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
  color: #888;
}
.move-btn-left-box{
  left:0px;
  bottom: 1px;
  justify-content: flex-start;
  background: linear-gradient(90deg,#fff 50%,hsla(0,0%,100%,0));
}
.move-btn-right-box{
  right:0px;
  bottom: 1px;
  justify-content: flex-end;
  background: linear-gradient(270deg,#fff 50%,hsla(0,0%,100%,0));
}

.jobslist-main-divider__2{
  height: 1px;
  margin-top: 25px;
  margin-bottom: 38px;
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  background-color: #e1e2e3;
}


/* main 컨텐츠 */

.jobslist-main-contents-bookmark-text{
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  line-height: 24px;
  color: #36f;
  font-size: 15px;
  font-weight: 600;
}
.jobslist-main-contents-bookmark-text>span{
  margin-top: 2px;
}


/* main 컨텐츠 - 카드 */

/* .jobslist-new-position-back{
  padding: 0 0 50px;
} */
.jobslist-new-position-contents{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
}


.jobslist-new-position-card{
  width: 100%;
  padding: 10px;
  position: relative;
}
.jobslist-new-position-card>img{
  width: 100%;
  height: 187.5px;
  border-radius: 4px;
}

.jobslist-new-position-card>div:nth-child(2){
  margin-top: 14px;
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  max-height: 2.4em;
  overflow: hidden;
}
.jobslist-new-position-card>div:nth-child(3){
  font-size: 14px;
  line-height: normal;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  color: #333;
  font-weight: 600;
} 
.jobslist-new-position-card>div:nth-child(4){
  font-weight: 400;
  color: #999;
  font-size: 14px;
  line-height: normal;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
}
.jobslist-new-position-card>div:nth-child(5){
  margin-bottom: 14px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  word-break: break-word;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.jobslist-bookmark-btn{
  position: absolute;
  top: 16px;
  right: 14px;
  color: #fff;
}
.jobslist-bookmark-btn>svg{
  width: 22px;
  height: 22px;
}
.plus-page-loading-box{
  width: 100%;
  height: 30px;
}



/* 미들 배너 */
.jobslist-middle-banner{
  cursor: pointer;
  background: #0d99ff;
  color: #fff;
  border-radius: 10px;
  padding: 28px 0 22px 120px;
  background-image: url(https://static.wanted.co.kr/images/type-test/typetest-banner-image.png);
  background-size: auto 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  margin: 35px 10px 50px;
}
.jobslist-middle-banner>div:nth-child(1){
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
}
.jobslist-middle-banner>div:nth-child(2){
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: .0056em;
  height: 32px;
}



