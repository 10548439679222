
/* top 배너 */
.resume-top-banner-back{
  width: 100%;
  height: 442px;
  background-image: url(https://static.wanted.co.kr/images/userweb/resume_intro/intro_img_web.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 50px;
}
.resume-top-banner-contents{
  width: 100%;
  text-align: center;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 0;
}
.resume-top-banner-contents>div:nth-child(1){
  font-weight: 700;
  font-size: 56px;
  line-height: 128.6%;
  letter-spacing: -.027em;
}
.resume-top-banner-contents>div:nth-child(2){
  font-weight: 400;
  font-size: 22px;
  line-height: 127.3%;
  letter-spacing: -.012em;
  margin-top: 10px;
}

.resume-top-banner-btns-box{
  margin-top: 50px;
}
.resume-top-banner-btns-box>button:nth-child(1){
  background: #fff;
  color: #36f;
  border: 1px solid #36f;
  border-radius: 25px;
  height: 54px;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: .0056em;
}
.resume-top-banner-btns-box>button:nth-child(2){
  margin-left: 10px;
  height: 54px;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: .0056em;
  color: #fff;
  background-color: #36f;
  border: none;
  border-radius: 25px;
}




/* like banner */
.resume-like-banner-back{
  padding: 100px 0px;
}
.resume-like-banner-title{
  font-weight: 700;
  font-size: 36px;
  text-align: center;
}

.resume-like-banner-contents-box{
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 20px;
}
.resume-like-banner-contents-box>div:nth-child(1){
  background-color: #f1f7fe;
}
.resume-like-banner-contents-box>div:nth-child(2){
  background-color: #f1f1fe;
}
.resume-like-banner-contents-box>div:nth-child(3){
  background-color: #f3f8ee;
}
.resume-like-banner-contents-box>div:nth-child(4){
  background-color: #ecf8fb;
}



.resume-like-banner-card{
  display: flex;
  flex-direction: column;
  width: 520px;
  height: 480px;
  padding-top: 50px;
  border-radius: 10px;
  text-align: left;
  justify-content: space-between;
}

.resume-like-banner-text-box{
  padding-left: 50px;
}
.resume-like-banner-text-box>div:nth-child(1){
  margin-top: 0;
  font-weight: 600;
  font-size: 26px;
  line-height: 135.7%;
}
.resume-like-banner-text-box>div:nth-child(2){
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 15px;
}
.resume-like-banner-card-img1{
  width: 520px;
  height: 312px;
}

.resume-like-banner-card-img2{
  width: 520px;
  height: 256px;
}
.resume-like-banner-card-img3{
  width: 520px;
  height: 282px;
}
.resume-like-banner-card-img4{
  width: 520px;
  height: 282px;
}


/* 작성배너 */

.resume-write-banner-back{
  padding-top: 100px;
  background-color: #f2f4f7;
  position: relative;
}
.resume-write-banner-title{
  text-align: center;
  font-weight: 600;
  font-size: 40px;
}
.resume-write-banner-img-box{
  margin-top: 50px;
}
.resume-write-banner-img-box>img{
  width: 100%;
}

.resume-write-banner-btns-box{
  padding-top: 50px;
  padding-bottom: 100px;
  margin-top: -5px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}
.resume-write-banner-btns-box>button:nth-child(1){
  margin-right: 10px;
  background-color: #fff;
  border: 1px solid #e1e2e3;
  color: #333;
  width: fit-content;
  height: 54px;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: .0056em;
  border-radius: 25px;
}
.resume-write-banner-btns-box>button:nth-child(2){
  width: fit-content;
  height: 54px;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: .0056em;
  color: #fff;
  background-color: #36f;
  border: none;
  border-radius: 25px;
}

.resume-write-banner-blur-box{
  position: absolute;
  width: 100%;
  height: 150px;
  background: linear-gradient(180deg,hsla(0,0%,97%,0),#f8f8f8);
  bottom: 160px;
}


/* ai 추천배너 */
.resume-recommend-back{
  height: 452px;
  background-color: #fff;
}
.resume-recommend-contents-box{
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
}
.resume-recommend-text-box-title{
  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: -.024em;
}
.resume-recommend-text-box-sub-title{
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  margin-top: 20px;
}
.resume-recommend-text-box-start-btn{
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: .0056em;
  color: #fff;
  background-color: #36f;
  border: none;
  border-radius: 25px;
  padding: 0 27px;
}





