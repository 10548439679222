.bookmark-main-back{
  margin-top: 50px;
}

.bookmark-main-title{
  font-size: 20px;
  font-weight: 700;
  color: #333;
  padding: 50px 0 20px;
}
.bookmark-main-contents-box{
  padding: 60px 0;
  text-align: center;
  margin: 240px 0;
  color: #d1d1d1;
  font-size: 24px;
}






