
.job-detail-main-back{
  padding-top: 70px;
}


/* 왼쪽박스 */
.job-detail-main-left-box-back{
  width: calc(100% - 360px);
  margin-right: 20px;
}

.job-detail-left-carousel-back{
  width: 100%;
  height: 450px;
}
.job-detail-left-carousel-contents{
  width: 100%;
  height: 100%;
}
.job-detail-left-carousel-contents>img{
  width: 100%;
  height: 100%;
}
.job-detail-left-title{
  margin-top: 40px;
  margin-bottom: 10px;
  color: #333;
  font-size: 22px;
  font-weight: 600;
  word-break: keep-all;
  word-wrap: break-word;
}

.job-detail-company-info>div:nth-child(1){
  color: #333;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  line-height: 1.4;
  margin-right: 10px;
}
.job-detail-company-info>div:nth-child(2){
  border: 1px solid #00aead;
  color: #00aead;
  border-radius: 2px;
  background-color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  height: 19px;
  line-height: 19px;
  padding: 0 5px;
  margin-right: 10px;
}
.job-detail-company-info>div:nth-child(3){
  padding: 0 10px;
  position: relative;
  white-space: nowrap;
  color: #999;
}
.job-detail-left-hashtags{
  margin-top: 20px;
  flex-wrap: wrap;
}
.job-detail-left-hashtags>span{
  margin-right: 6px;
  margin-bottom: 10px;
  padding: 9px 14px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #333;
  background-color: #f3f5f8;
  border-radius: 25px;
}

.job-detail-left-explanation{
  color: #333;
  font-weight: 400;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 16px;
  line-height: 1.75;
}
.job-detail-left-explanation-title{
  margin: 20px 0 0;
  font-weight: 600;
  color: #333;
  font-size: 16px;
  line-height: 1.75;
}
.job-detail-left-tech-stack{
  margin-top: 5px;
  margin-bottom: 60px;
}

.job-detail-left-tech-stack>span{
  background-color: #e4f4ec;
  border-radius: 20px;
  padding: 9px 14px;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 600;
  margin-right: 8px;
}


.job-detail-left-divider{
  height: 1px;
  width: 100%;
  background-color: #aeaeae;
}

.job-detail-left-day-area{
  margin-top: 20px;
}
.job-detail-left-day-area>div:nth-child(1){
  color: #999;
  width: 80px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  vertical-align: text-top;
}
.job-detail-left-day-area>div:nth-child(2){
  color: #333;
  width: calc(100% - 80px);
  font-size: 16px;
  font-weight: 600;
  vertical-align: text-top;
}


/* 오른쪽박스 */
.job-detail-main-right-box-back{
  width: 340px;
  height: 330px;
  border: 1px solid #e1e2e3;
  background-color: #fff;
  padding: 24px 20px;
  position: sticky;
  top: 60px;
}
.job-detail-right-title{
  justify-content: space-between;
  position: relative;
}
.job-detail-right-title>div:nth-child(1){
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}
.job-detail-right-title>button:nth-child(2){
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e1e2e3;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: 10px;
}
.job-detail-right-share-icon{
  width: 25px;
  height: 25px;
  margin-top: 3px;
  margin-left: -2px;
  color: skyblue;
}
.job-detail-right-money-box{
  margin: 24px 0;
}
.job-detail-right-money-box>div{
  width: 50%;
}
.job-detail-right-money-box-text{
  font-size: 14px;
  font-weight: 600;
  color: #999;
  margin-bottom: 8px;
  line-height: 1.2;
}
.job-detail-right-money-box-money{
  font-size: 15px;
  color: #333;
  font-weight: 600;
}
.job-detail-right-bookmark-btn{
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #fff;
  border: 1px solid #36f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #36f;
  text-align: center;
  justify-content: center;
}
.job-detail-right-apply-btn{
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: #36f;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
}

.job-detail-right-like-btn{
  margin: 24px 0 0;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #e1e2e3;
  margin-right: 12px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
    
}




